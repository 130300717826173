import React from 'react'
import { graphql, Link } from 'gatsby'
import get from 'lodash/get'
import Helmet from 'react-helmet'

import Layout from '../components/layout'
import PageWrapper from '../components/pageWrapper'
import { DonationLink } from '../components/donationButton'
import { renderRichText } from '../lib/richTextRender'

class BlogIndex extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const [instructions] = get(
      this,
      'props.data.allContentfulInstructions.edges'
    )
    const { node } = instructions
    return (
      <Layout>
        <PageWrapper>
          <Helmet title={`${node.title} | ${siteTitle}`} />
          <div className="wrapper p-3">
            <div className="d-flex align-items-end">
              <div>
                <h2 className="font-weight-bold">{node.title}</h2>
                <p className="font-weight-bold">{node.intro}</p>
              </div>
              <div className="vl"></div>
              <DonationLink />
            </div>
            <hr />
            <div>{renderRichText(node.description.json)}</div>
            <hr />
            <p className="mb-2 text-muted">{node.contactMeViaMail}</p>
            <p className="mb-2 text-muted font-weight-bold">{node.donations}</p>
          </div>
        </PageWrapper>
      </Layout>
    )
  }
}

export default BlogIndex

export const pageQuery = graphql`
  query IntroductionQuery {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulInstructions {
      edges {
        node {
          intro
          contactMeViaMail
          donations
          title
          subtitle
          description {
            json
          }
        }
      }
    }
  }
`
